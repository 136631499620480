import React from 'react';

import Layout, {Container, ContentWithPaddingXl} from '@components/Layout';
import Header from '@components/Header';
import Legal from '@sections/Legal';
import CookieBanner from '@components/Cookies/CookieBanner';
import { SectionContainer } from '@components/common/Layout';
import { UseContentfulPrivacy } from '@hooks/UseContentfulPrivacy';

const PrivacyPage = () => {

  const { nodes } = UseContentfulPrivacy();
  return (
  <Layout>
    <Header />
    <SectionContainer id="home">
    
      <Legal headingText={nodes[0].titleHeading}content={nodes[0].content?.json}/> 
    </SectionContainer>    
    <CookieBanner/>
  </Layout>
  );
}

export default PrivacyPage;
